.serviceItem_block {
  height: 190px;
  width: 380px;
  background: rgba(217, 217, 217, 0.05);
  border-radius: 25px;
  transition: .3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  &:hover {
    transition: .3s ease;
    height: 600px;

    .icon_block {
      transition: .3s ease;
      background: #910420;

    }

    .text_block {
      p {
        transition: .5s ease;
        opacity: 1;
      }
    }
  }
}

.icon_block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 127px;
  position: absolute;
  margin-top: -50px;
  height: 127px;
  background: white;
  border-radius: 50%;
  transition: .3s ease;
}

.text_block {
  text-align: center;
  padding: 0 58px;
  margin-top: 100px;

  h3 {
    font-family: Montserrat, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    text-align: center;
  }

  p {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    opacity: 0;

    margin-top: 50px;
  }
}

@media screen and (max-width: 1440px) {
  .serviceItem_block {
    width: 280px;

    .text_block {
      padding: 0 10px;
    }
  }
}

@media screen and (max-width: 991px) {

  .serviceItem_block {
    height: 400px;
    width: 150px;
    background: rgba(217, 217, 217, 0.05);
    border-radius: 25px;
    transition: .3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    &:hover {
      height: 400px;
    }


    .icon_block {
      background: #910420;

      img {
        padding: 10px;
        width: inherit;
        height: inherit;
      }
    }

    .text_block {
      p {
        opacity: 0;
      }
    }
  }
}
@media screen and (max-width:991px) {
  .serviceItem_block {
    margin-top: 20px;
    width: 100%;
    height: 100%;
.text_block {
    p {
      opacity: 1;
    }
  }

  }
  
}

@media screen and (max-width: 767px) {
  .serviceItem_block {
    margin-top: 20px;
    width: 100%;
    height: 100%;

    .icon_block {
      background: #910420;
      width: 50px;
      height: 50px;
      margin-top: -25px;

      img {
        padding: 10px;
        width: inherit;
        height: inherit;
      }
    }

    .text_block {
      margin-top: 50px;

      p {


        margin-top: 10px;
        opacity: 1;
        font-size: 12px;
        line-height: 15px;
      }

      h3 {
        font-size: 18px;
      }
    }
  }
}