.project_block {
  height: 650px;
  width: 380px;
  background: #fff;
  border-radius: 25px;
  transition: .3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 77px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  text-align: center;
  gap: 24px;

  &:hover{
    transition: .3s ease;
    box-shadow: 0 5px 10px 10px rgba(218, 223, 225, 0.3);
  }

  h1 {
    font-family: Montserrat, sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 59px;
    letter-spacing: 0;
    text-align: center;
  }

  p {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;

  }
}
@media (min-width:992px) and (max-width:1190px) {
  .project_block {
    width: 300px !important;
    height: 650px;
  }
  .project_block{
    padding: 0 50px;
  }
}
@media screen and (max-width: 1201px) {
  .project_block {
    width: 330px;
    height: 650px;
  }
  .project_block{
    padding: 0 50px;
  }
}


@media screen and (max-width: 991px) {
  .project_block {
    width: 100%;
    height: 450px;
    gap: 24px;
    padding: 0 30px;

    h1 {
      font-size: 24px;
      line-height: 28px;
    }
  }
}