.primaryButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 35px;
  gap: 10px;
  border-radius: 5px;
  border: none;
  width: 200px;
  max-height: 50px;
  white-space: nowrap;


  font-family: montserrat, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  color: white;

  background-color: #910420;
  transition: .2s ease;


  &:hover {
    background-color: #DE0732;
    transition: .2s ease;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
  }
}


.whiteButton {
  width: 200px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 35px;
  gap: 10px;
  border-radius: 5px;
  border: none;
  white-space: nowrap;
  max-width: 250px;
  background: white;


  font-family: montserrat, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  color: black;
  transition: .2s ease;


  &:hover {
    background-color: #1D1D1D;
    transition: .2s ease;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    color: white;
  }
}

.primaryButton__input {
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
  white-space: nowrap;

  gap: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  max-height: 50px;


  font-family: montserrat, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  color: white;

  background-color: #910420;
  transition: .2s ease;


  &:hover {
    background-color: #DE0732;
    transition: .2s ease;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
  }

}