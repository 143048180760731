.navbar {
  display: flex;
  justify-content: space-between;
  padding: var(--all-section-right-left-padding);
  align-items: center;
  background: none;


  &__link_section {
    display: flex;
    width: 100%;
    list-style-type: none;
    justify-content: flex-end;
    align-items: center;
    gap: 36px;
    flex-wrap: wrap;

    li {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      cursor: pointer;

      a {
        text-decoration: none;
        color: #1D1D1D;

        &:hover {
          color: #4F4F4F;
        }
      }

    }
  }
}

@media screen and (max-width: 1280px) {
  .navbar {

    &__link_section {
      gap: 18px;

      li {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    padding: 50px 0;
    margin: 0 16px;
    display: none;
  }
  .image_burger {
    position: fixed;
    right: 45px !important;
    background: #BABABA;
    padding: 8px;
    top: 15px;
    z-index: 10;
    border-radius: 5px;
  }
}

@media (max-width:768px) {
  .image_burger {
    position: fixed;
    right: 20px !important;
    background: #BABABA;
    padding: 8px;
    top: 15px;
    z-index: 10;
    border-radius: 5px;
  }
  .navbar__mobile_version {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    padding: 24px !important;
  
  }
}

.showWine {
  position: absolute;
  max-width: 50px;
  opacity: 0;
  transition: .2s ease;

  &:hover {
    transition: .2s ease;
    opacity: 1;
  }
}

.navbar__mobile_version {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  padding: 24px 3rem;

}

.image_burger {
  position: fixed;
  right: 20px;
  background: #BABABA;
  padding: 8px;
  top: 15px;
  z-index: 10;
  border-radius: 5px;
}

@media screen and (min-width: 992px) {
  .navbar__mobile_version {
    display: none;
  }
}

.nav_link_part {
  z-index: 20;
  width: 70vw;
  right: 0;
  height: 100vh;
  top: 0;
  background: black;
  position: fixed;
  overflow-y: scroll;
  padding: 20px;
}

.content_block {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ul {
    list-style-type: none;
    text-align: center;
    display: flex;
    margin-top: 80px;
    flex-direction: column;
    gap: 47px;
    color: white;

    li {
      a {
        text-decoration: none;
        color: white;
      }
    }

  }
}
