@import url('http://fonts.cdnfonts.com/css/montserrat');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

body {
  height: 100%;
  width: 100%;
}


.container {
    --all-section-right-left-padding: 24px 128px 0 128px;
    width: 100%;
}


#e1 {
    transition: .2s ease;

}

#ex1:hover {
    fill: #4f4f4f;
    transition: .2s ease;
}

/*
@keyframes showNav {
    0% {
        transform: translateX(70vw);
    }
    100% {
        transform: translateX(0vw);
    }
}

@keyframes closeNav {
    0% {
        transform: translateX(0vw);
    }
    100% {
        transform: translateX(70vw);
    }
}*/


.compares {
    grid-column-start: 1;
    grid-column-end: 3;
}

@keyframes showTab {
    0% {
        display: none;
    }
    100% {
        display: block;
    }
}


.showColor {
    position: absolute;
    content: "  ";
    width: 100%;
    height: 1000%;
    opacity: 0.75;
    transition: 2s ease;
    z-index: 1;
    overflow: hidden;
    background: #1D1D1D;
}

.margin {
    margin-top: -2px;
}

.first_image {
    margin-top:11px;
    border-radius: 10px;
    transform: rotate(-20deg) !important;
}

.rotate{
    transform: rotate(20deg);
}


button:disabled, button[disabled]:hover {
    background-color: #1D1D1D;
    color: #4f4f4f;
    border: 1px solid #4f4f4f;
    cursor: not-allowed;
}

textarea{
    padding: 10px;
    font-size: 16px;
}

button{
    cursor: pointer;
}