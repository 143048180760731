.footer {
  height: 425px;
  background-image: url("../../images/footer-bg.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}


.inner_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 227px;
  gap: 70px;
  width: 776px;
}

.text_block {
  ul {
    display: flex;
    list-style-type: none;
    gap: 47px;

    li {
      cursor: pointer;
    }

    a {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: #BABABA;

      text-decoration: none;
    }
  }
}

.link_block {
  display: flex;
  gap: 32px;
  margin-top: 55px;
}

.lic_text {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  margin-top: 40px;
  color: #BABABA;

}


.link {
  text-decoration: none;
  color: #BABABA;

  &:hover {
    color: #4f4f4f;
  }
}

@media screen and (max-width: 991px) {

  .text_block {
    ul {
      gap: 30px;
    }
  }
  .footer {
    height: 325px;
    font-size: 8px;
  }
  .inner_footer {
    gap: 20px;

    img {
      max-width: 300px;
    }
  }
  .link_block {
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 25px;

    &__link_item {
      max-width: 16px;

      svg {
        max-width: 16px;
      }
    }
  }
  .lic_text {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .inner_footer {
    flex-direction: column;

    img {
      max-width: 200px;
      padding-bottom: 25px;
    }
  }
  .text_block{
    p{
      text-align: center;

    }
  }
}

@media screen and (max-width: 436px) {
  .link_block {
    display: none;
  }
  .text_block {
    ul {
      justify-content: center;
      gap: 30px;
    }
  }
}
