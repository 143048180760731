.member_card {
  width: 380px;
  height: 650px;
  background: white;
  border-radius: 25px;
  border: 3px solid #910420;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.1);
  padding: 0 64px;
  z-index: 2;
  transition: .3s ease;


  &:hover {
    transition: .3s ease;
    box-shadow: 0 17px 10px rgba(0, 0, 0, 0.3)
  }




  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    max-width: 151px;
    margin-top: -75px;
  }

  h3 {
    margin-top: 100px;
    font-family: Montserrat, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0;
    text-align: center;
  }

  h4 {
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0;
    text-align: center;
    margin-top: 28px;
  }

  p {
    margin-top: 34px;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    max-height: 300px;
    min-height: 300px;

  }

  .link_block {
    display: flex;
    gap: 32px;
    margin-top: 32px;

    img {
      transition: .2s ease;
    }

    &__link_item {

      img:hover {
        transform: scale(1.2);
        transition: .2s ease;
        filter: brightness(50%);
      }
    }
  }
}

@media (min-width:992px) and (max-width:1190px) {
  .member_card {
    width: 300px !important;
    height: 650px;
  }
  .member_card{
    padding: 0 50px;
  }
}

@media screen and (max-width: 1201px) {
  .member_card {
    width: 330px;
    height: 650px;
  }
  .member_card{
    padding: 0 50px;
  }
}

@media screen and (max-width: 991px) {
  .member_card {
    width: 100%;
    // height: 480px;
    gap: 0;
    padding: 0 30px;
    margin-top: 50px;
  }
}


@media screen and (max-width: 767px) {
  .member_card {
    height: 100%;

    p, h4 {
      margin-top: 10px;
    }

    h3 {
      margin-top: 80px;
    }
  }
}