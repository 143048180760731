.mainSection__wrapper {
  background-image: url("../images/bg-cover.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  background-color: #1D1D1D;
}

@media screen and (min-width: 1680px) {
  .mainSection__wrapper {
    background-image: url("../images/bg-cover.png");
  }
}


.mainSection {
  width: 100%;
  padding: var(--all-section-right-left-padding);
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 82px);

  h1 {
    font-family: Montserrat, sans-serif;
    font-size: 64px;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0;
    text-align: left;
    transition: all 2s ease 0s; opacity: 1;
  }

  p {
    margin-top: 7px;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #4F4F4F;
    margin-bottom: 47px;
  }
  a{
    text-decoration: none;
  }
}


@media screen and (min-width: 1920px) {
  .mainSection {
    h1 {
      font-size: 108px;
      line-height: 136px;
    }

    p {
      font-size: 24px;
      line-height: 36px;
    }
  }
}

@media screen and (max-width: 1320px) {
  .mainSection {
    height: calc(100vh - 130px);
  }
}

.servicesSection {
  overflow: hidden;
  position: relative;
  width: 100%;
  background: #1D1D1D;
  padding: var(--all-section-right-left-padding);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    margin-top: 104px;
    color: white;

    h1 {
      font-family: montserrat, sans-serif;
      font-size: 72px;
      font-weight: 700;
      line-height: 88px;
      letter-spacing: 0;
      text-align: center;
    }

    .header_description {
      max-width: 800px;
      margin-top: 35px;
      text-align: center;

      font-family: montserrat, sans-serif;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;

    }
  }

  &__itemBlock {
    height: 700px;
    margin-top: 90px;
    display: flex;
    flex-wrap: wrap;
    gap: 23px;
  }
}

@media screen and (max-width: 1440px) {
  .servicesSection {
    padding: 0 50px;
  }
}


.bottom_wave {
  overflow: hidden;
  position: relative;
  width: 100%;
  background-image: url("../images/services/bottom_wave.png");
  height: 100%;
  min-height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom:10px;
}


.otherSection_wrapper {
  margin-top: -20px;
  width: 100%;
  background: linear-gradient(205.8deg, #1B0005 0.89%, #35010B 60.93%, #910420 101.6%);
  // padding: var(--all-section-right-left-padding);
  z-index: 2;
}


.projectServices {
  z-index: 2;
  display: grid;
  justify-items: center;

  &__header {
    margin-top: 120px;
    color: white;

    h1 {
      font-family: montserrat, sans-serif;
      font-size: 72px;
      font-weight: 700;
      line-height: 88px;
      letter-spacing: 0;
      text-align: center;
    }

    .header_description {
      margin-top: 35px;

      max-width: 800px;

      text-align: center;

      font-family: montserrat, sans-serif;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;

    }
  }

  &__project_services {

    margin-top: 64px;
    gap: 24px;
    align-self: center;
    justify-content: center;
    display: flex;

  }

}

.test{
  width: 100%;
}

.test:nth-child(2) {
  margin-top: 100px;
}

.test:nth-child(3) {
  margin-top: 200px;
}

.wine_header {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s ease;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 150px;
    max-height: 150px;
    opacity: 0;
    transition: .3s ease;
    z-index: -1;
    transform: rotate(180deg);
  }

  &:hover {
    transition: .3s ease;

    img {
      transition: .3s ease;
      opacity: 1;
    }
  }
}


.team_section {
  display: grid;
  justify-items: center;
  background-image: url("../images/backgound_circles.svg");
  background-repeat: no-repeat;
  background-position: center;
  overflow: visible;
  z-index: 2;
  height: 100%;
  background-size: 100% 80%;
  margin-top: 196px;

  &__header {
    margin-top: 104px;
    color: white;

    h1 {
      font-family: montserrat, sans-serif;
      font-size: 72px;
      font-weight: 700;
      line-height: 88px;
      letter-spacing: 0;
      text-align: center;
    }

    .header_description {
      margin-top: 35px;
      text-align: center;
      max-width: 800px;

      font-family: montserrat, sans-serif;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;

    }
  }

  &__team_block {
    display: flex;
    justify-content: center;
    margin-top: 139px;
    gap: 25px;
  }
}

.partners_block {
  &__header {
    margin-top: 255px;
    color: white;

    h1 {
      font-family: montserrat, sans-serif;
      font-size: 72px;
      font-weight: 700;
      line-height: 88px;
      letter-spacing: 0;
      text-align: center;
    }
  }


  .partners_icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    margin-top: 123px;


    &__icon {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 5px;
      padding: 35px;
      transition: .5s ease;

      &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
        background: #AFAFB0;
        transition: .5s ease;
      }
    }
  }
}


.winepaper_block {
  background: #910420;
  width: 980px;
  height: 750px;
  border-radius: 25px;
  margin-bottom: 200px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
   //width: 100%;
   //height: 100%;
  display: flex;

  .photo {
    max-width: 504px;
    max-height: 750px;

    img {
      width: 700px;
      height: 700px;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      margin: 0px -100px;
      padding: 80px;
    }
  }

  .text_block {
    z-index: 1;
    h1 {
      color: white;
    }

    p {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
      color: white;
      font-style: italic;

    }

    padding: 76px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }
}

@media (min-width:992px) and (max-width:1190px) {
  .winepaper_block{
    display: flex;
    justify-content: center;
    align-items: center;
    .photo {
      max-width: 504px;
      max-height: 750px;
  
      img {
        width: 100%;
        height: 100%;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        margin: 0px 0px;
        padding: 0px;
      }
    }
  }
}

.connect_section {
  display: flex;
  justify-content: center;
  gap: 160px;

  width: 100%;
  padding-bottom: 96px;
  margin-top: 96px;


  &_text {
    color: white;

    p {
      margin-top: 16px;
      font-family: Montserrat, sans-serif;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      letter-spacing: 0;
    }
  }

  .input_block {
    display: flex;
    align-items: center;

    input {
      width: 100%;
      padding: 11px 28px;
      max-width: 280px;
      border: none;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      height: 50px;
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 29px;
      letter-spacing: 0;

      &:focus {
        outline: 1px solid black;
      }
    }
  }
}


@media screen and (max-width: 1280px) {
  .winepaper_block {
    .text_block {
      p {
        font-size: 12px;
      }
    }
  }
}


@media screen and (max-width: 991px) {
  .servicesSection {
    &__itemBlock {
      justify-content: center;
    }
  }

  .projectServices {
    &__project_services {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;

    }
  }
  .team_section {
    &__team_block {
      flex-direction: column;
    }
  }

  .connect_section {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .winepaper_block {
    width: 780px;
    height: 500px;

    .photo {
      img {
        height: 500px;
        width: 500px;
      }
    }

    .text_block {
      gap: 20px;

      p {
        font-size: 15px;
      }
    }
  }
}



@media screen and (max-width: 991px) {
  .test:nth-child(2) {
    margin-top: 0;
  }

  .test:nth-child(3) {
    margin-top: 0;
  }
  .mainSection {
    padding: 0 3rem;

    h1 {
      font-size: 36px;
      line-height: 40px;
    }
  }
  .servicesSection {
    padding: 0 45px 120px 45px;

    &__header {
      max-width: 100%;

      h1 {
        font-size: 36px;
      }

      .header_description {
        text-align: center;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  .projectServices {
    &__header {
      margin-top: 90px;

      .header_description {
        margin-top: 0;
      }

      h1 {
        font-size: 36px;
      }

      .header_description {
        text-align: center;
        font-size: 14px;
        line-height: 18px;
      }
    }

    &__itemBlock {
      display: grid;
    }
  }
  .partners_block {
    &__header {
      h1 {
        font-size: 36px;
      }

      .header_description {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
  .team_section {
    margin-top: 100px;
    background-image: none;

    &__header {
      margin-top: 20px;

      h1 {
        font-size: 36px;
      }

      .header_description {
        margin-top: 0;
      }

      .header_description {
        font-size: 12px;
        line-height: 15px;
      }
    }

    &__team_block {
      margin-top: 50px;
      gap: 100px;
    }
  }

  .winepaper_block {
    height: 100%;
    width: 320px;
    flex-direction: column-reverse;
    margin-bottom: 0;

    .photo {
      img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 0;
        padding: 10px;
        margin-left: 0px
      }
    }

    .text_block {
      gap: 0;
      padding: 10px 5px;
      max-width: 100%;
      margin: 20px;
      text-align: center;


      h1 {
        margin-top: 0px;
        font-size: 18px;
      }

      p {
        margin-top: 10px;
        font-size: 14px;
        line-height: 18px;
      }
    }

    button {
      margin-top: 20px;
    }
  }
  .connect_section {
    h1 {
      font-size: 20PX;
    }

    margin-top: 100px;
    width: 100%;

    input {
      width: 200px;
    }
  }

  .otherSection_wrapper {
    padding: 3rem;
  }

  .partners_block {

    .partners_icons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0px;

      &__icon {
        padding: 15px;
        max-width: 90px;
        max-height: 90px;

        img {
          max-width: 60px;
          max-height: 60px;
        }
      }
    }
  }
  .mainSection__wrapper {
    background-image: url("../images/mobile_bg.png");
    background-size: inherit;
    background-size: contain;
  }



  .servicesSection {
    &__itemBlock {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;

      &:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

  .Service_serviceItem_block__ZNWRa {
    margin-top: 20px;
    width: 100%;
    height: 100%;
}

}
@media screen and (max-width: 768px) {
  .test:nth-child(2) {
    margin-top: 0;
  }

  .test:nth-child(3) {
    margin-top: 0;
  }
  .mainSection {
    padding: 0 20px;

    h1 {
      font-size: 36px;
      line-height: 40px;
    }
  }
  .servicesSection {
    padding: 0 20px 120px 20px;
    margin-bottom: 2rem;

    &__header {
      max-width: 100%;

      h1 {
        font-size: 36px;
      }

      .header_description {
        text-align: center;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  .projectServices {
    &__header {
      margin-top: 90px;

      .header_description {
        margin-top: 0;
      }

      h1 {
        font-size: 36px;
      }

      .header_description {
        text-align: center;
        font-size: 14px;
        line-height: 18px;
      }
    }

    &__itemBlock {
      display: grid;
    }
  }
  .partners_block {
    &__header {
      h1 {
        font-size: 36px;
      }

      .header_description {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
  .team_section {
    margin-top: 100px;
    background-image: none;

    &__header {
      margin-top: 20px;

      h1 {
        font-size: 36px;
      }

      .header_description {
        margin-top: 0;
      }

      .header_description {
        font-size: 12px;
        line-height: 15px;
      }
    }

    &__team_block {
      margin-top: 50px;
      gap: 100px;
    }
  }

  .winepaper_block {
    height: 100%;
    width: 320px;
    flex-direction: column-reverse;
    margin-bottom: 0;

    .photo {
      img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 0;
        padding: 10px;
        margin-left: 0px
      }
    }

    .text_block {
      gap: 0;
      padding: 10px 5px;
      max-width: 100%;
      margin: 20px;
      text-align: center;


      h1 {
        margin-top: 0px;
        font-size: 18px;
      }

      p {
        margin-top: 10px;
        font-size: 14px;
        line-height: 18px;
      }
    }

    button {
      margin-top: 20px;
    }
  }
  .connect_section {
    h1 {
      font-size: 20PX;
    }

    margin-top: 100px;
    width: 100%;

    input {
      width: 200px;
    }
  }

  .otherSection_wrapper {
    padding: 15px;
  }

  .partners_block {

    .partners_icons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0px;

      &__icon {
        padding: 15px;
        max-width: 90px;
        max-height: 90px;

        img {
          max-width: 60px;
          max-height: 60px;
        }
      }
    }
  }
  .mainSection__wrapper {
    background-image: url("../images/mobile_bg.png");
    background-size: inherit;
  }



  .servicesSection {
    &__itemBlock {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;

      &:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

}
