.termOfUse__wrapper {
  height: 140vh;
  width: 100%;
  background-image: url("../images/home-bg.png");
  background-size: cover;
}

.contactUs__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;


  h1 {
    font-family: Montserrat, sans-serif;
    font-size: 64px;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0;
    text-align: left;

  }
}

.text_block {
  width: 100%;
  height: 100%;
  max-width: 982px;
  max-height: 570px;
  background: #D9D9D9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;
  border-radius: 5px;
  margin-top: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  h2 {
    margin-top: 66px;
    font-family: Montserrat, sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0;
    text-align: left;
  }

  p {
    padding: 10px;
    margin-top: 50px;
    max-height: 400px;
    overflow-y: scroll;

  }
}


p::-webkit-scrollbar {
  width: 10px;
}

/* Track */
p::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #BABABA;
}

/* Handle */
p::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #1D1D1D;
}

/* Handle on hover */
p::-webkit-scrollbar-thumb:hover {
  background: #1D1D1D;
}

@media screen and (max-width: 767px) {
  .contactUs__content {
    h1 {
      text-align: center;
    }
  }
  .text_block{
    padding: 10px;
    width: 90%;
    margin-top: 0;
  }
}
